.w_card {
  background: rgba(255, 255, 255, 0.37) !important;
  border: 3px solid #ffffff !important;
  box-sizing: border-box !important;
  backdrop-filter: blur(4px) !important;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 22px !important;
}

.footer_text {
  background: linear-gradient(180deg, rgba(0, 96, 255, 0.8) 0%, #23b543 100%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
