@import "Invoice";

.lineItems {
  margin-bottom: $rhythm * 4;
  // .addItem {
  //   padding-top: $rhythm;
  //   button {
  //     @extend %flex-center;
  //     background: none;
  //     padding: $rhythm / 2;
  //     border: 1px solid $addColor;
  //     color: $addColor;
  //     border-radius: $borderRadius;
  //     float: right;
}
.addIcon {
}

.gridTable {
  margin: 0 auto;
  border: 1px solid $tableBorderColor;
  border-radius: 10px;
  // width: 40vw!important;
  box-shadow: 4px -4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: grid;
  & > .row {
    display: grid;
    grid-template-columns: 4fr repeat(4, 4fr) 4fr 4fr 1fr; //repeat(7, 1fr);
    border-bottom: 1px solid $tableBorderColor;
    // width: 100%;
    &:last-of-type {
      border-bottom: none;
    }
    &.header > div {
      text-align: center;
      font-weight: bold;
      // background: $boxBgColor;
    }
    &.editable > div {
      padding: $rhythm / 2;
    }
    & > div {
      @extend %flex-center;
      padding: ($rhythm/2);
      border-left: 1px solid $tableBorderColor;
      width: 100%;
      line-height: $rhythm;
      max-width: $rhythm;
      &:first-of-type {
        padding: ($rhythm/2) 0;
        border-left: none;
      }
      &:last-of-type {
        padding: ($rhythm/2) 0;
      }
      &:first-of-type,
      &:nth-child(4),
      &:last-of-type {
        text-align: center;
      }
      & > .deleteItem {
        @extend %flex-center;
        color: $removeColor;
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
      }
    }
  }
  .currency > input[type="number"] {
    max-width: $rhythm * 4;
  }
}

.listDraggingOver {
  background: #ddd;
  background-image: repeating-linear-gradient(
    30deg,
    rgba(#fff, 0.2),
    rgba(#fff, 0.2) 30px,
    transparent 0,
    transparent 60px
  );
}

.listItemDragging {
  background: #f9fff9;
  border: 1px solid $tableBorderColor;
}

@media print {
  .lineItem {
    margin-top: 0px !important;
    background-color: #444;
  }
}
