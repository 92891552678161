/* body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */

.container {
  width: 100%;
  height: 100vh;
}

.sigContainer {
  /* width: 80%; */
  position: relative;
  width: 400px;
  height: 200px;
}

.sigPad {
  position: absolute;
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}
