$rhythm: 15px;

$invoiceMaxWidth: 900px;
$borderRadius: 4px;

$tableBorderColor: rgb(231, 231, 231);
$boxBgColor: #eee;
$bgColor: white;
$addColor: darkgreen;
$removeColor: darkred;

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.currency {
  text-align: right;
  word-break: break-word;
}
@media print { 
  .no-printme  { display: none;}
  .printme  { display: block;}
}