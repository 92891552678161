@import url("https://fonts.googleapis.com/css2?family=Andika&family=Arimo:wght@700&family=Cairo:wght@700&display=swap");

.invoice-box {
  /* visibility: hidden; */
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.headings td {
  background: #eee !important;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 0.2%;
  padding-top: 0.2%;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.body {
  background-image: #b8923d !important;
}
@media print {
  /* .table_full{
         width: 100%;
        background-color: red;
        -webkit-print-color-adjust: exact; 
    }
    .invoice-box{
        width: 100%;
        background-color: lightyellow;
        -webkit-print-color-adjust: exact; 
    } */

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  #footer {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .companyLogo {
    max-height: 75px;
    max-width: 75px;
    transform: scale(1.6);
    /* margin: 5%; */
  }

  .logoBox {
    height: auto;
    width: auto;
    min-width: 150px;
    max-width: 220px;
    padding: 5%;
    border: 3px solid #b8923d;
    font-family: "Arimo", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 10%;
    text-align: center;
    word-wrap: break-word;
  }

  .invoice-box {
    width: 100%;
    visibility: visible;
  }

  .iv_n {
    font-family: "Andika", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: black;
  }
  .iv_date {
    font-family: "Andika", sans-serif;
    font-size: 14px;
    font-weight: 100;
    color: #afaeae;
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .bottom_report_heading {
    /* margin-bottom: 1%; */
    color: #555454;
    font-family: "Andika", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  .bottom_report {
    width: 80% !important;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: black;
  }
  .headings {
    border-bottom: 2px solid #e8ddd5;
  }
  .line {
    background-color: #b8923d !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    height: 2px !important;
  }
  .line2 {
    background-color: #b8923d !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    height: 2px !important;
    margin-bottom: 6%;
  }
  .line3 {
    background-color: #b8923d !important;
    -webkit-print-color-adjust: exact;
    /* width: 100%; */
    height: 2px !important;
  }
  .headingT {
    margin-left: 3%;
    width: 18%;
    font-family: "Cairo", sans-serif;
    font-weight: 900;
    font-size: 18px;
  }
  .headingTp {
    margin-left: 3%;
    width: 16%;
    font-family: "Cairo", sans-serif;
    font-weight: 900;
    font-size: 18px;
  }
  .headings td {
    color: #afaeae;
    font-family: "Arimo", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }
  .details {
    color: #afaeae;
    font-family: "Andika", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  .invoice-box table tr.headings td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }
  .footer_text {
    margin-top: -2%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-left: 2%;
    font-size: 9.5px;
    color: #a3a2a2;
    line-height: 14px;
    text-align: center;
  }
  .collapse-close {
    display: none;
  }

  .invoiceCus {
    font-family: "Andika", sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .invoiceCus_details {
    font-family: "Andika", sans-serif;
    font-size: 16px;
    font-weight: 100;
    color: #afaeae;
  }
  .invoiceCus_bg {
    padding: 2%;
    background: rgba(232, 221, 213, 0.38) !important;
    -webkit-print-color-adjust: exact;
    width: 100%;
    display: flex;
  }
  .invoice_S {
    width: 100%;
    text-align: center;
    flex: 1;
  }
  .invoice_I {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .arrow_l {
    transform: scale(2);
    max-height: 75px;
    max-width: 75px;
    margin: auto;
  }

  .top {
    display: flex;
    justify-content: space-between;
    margin: 5%;
    margin-right: 6%;
    margin-left: 10%;
    align-items: center;
  }
  .bottom_text {
    font-family: "Andika", sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: #a3a2a2;
  }
  .table_border {
    height: 100%;
    width: 100%;
    border: 2px solid greenyellow;
  }
  .phoneBold {
    color: rgb(88, 88, 88);
    font-weight: 100;
  }
  .td_first {
    min-width: 250px;
    max-width: 250px;
    padding-left: 5%;
    word-wrap: break-word;
  }
}
